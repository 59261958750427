@use '@angular/material' as mat;
@use 'dark' as q9-dark;
@use 'light' as q9-light;
@use 'utils' as utils;
@use '@ng-matero/extensions' as mtx;

@include mat.all-component-themes(q9-light.$theme);
@include mtx.all-component-themes(q9-light.$theme);

@include utils.extendTheme() {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0;
  height: 100vh;
}

@include utils.extendTheme('light') {
  @include mat.all-component-colors(q9-light.$theme);
  @include mtx.all-component-colors(q9-light.$theme);

  @include utils.generateTokens(
    (
      primary: q9-light.$primary,
      accent: q9-light.$accent,
      warn: q9-light.$warn,
      surface: q9-light.$extra,
      violet: q9-light.$violet,
      yellow: q9-light.$yellow,
      green: q9-light.$green,
      cerulean: q9-light.$cerulean
    )
  );
}

@include utils.extendTheme('dark') {
  @include mat.all-component-colors(q9-dark.$theme);
  @include mtx.all-component-colors(q9-dark.$theme);

  @include utils.generateTokens(
    (
      primary: q9-dark.$primary,
      accent: q9-dark.$accent,
      warn: q9-dark.$warn,
      surface: q9-dark.$extra,
      violet: q9-dark.$violet,
      yellow: q9-dark.$yellow,
      green: q9-dark.$green,
      cerulean: q9-dark.$cerulean
    )
  );
}

@import 'overrides';
