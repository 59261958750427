@use '@angular/material' as mat;
@use '../utils' as utils;

@include utils.extendTheme() {
  --mat-select-trigger-text-size: 14px;
  --mat-optgroup-label-text-size: 14px;

  .mat-mdc-select-panel {
    padding: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    --mat-option-hover-state-layer-color: var(--q9-primary-100);
    --mat-option-selected-state-layer-color: var(--q9-primary-300);
    --mat-option-selected-state-label-text-color: var(--q9-accent-100-contrast);
  }

  .mat-mdc-option-multiple {
    --mat-option-focus-state-layer-color: var(--q9-primary-300);
    --mdc-list-list-item-selected-container-color: var(--q9-primary-300);
    --mat-option-selected-state-label-text-color: var(--q9-accent-100-contrast);
  }

  .mdc-list-item--selected.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    --mat-option-hover-state-layer-color: var(--q9-primary-300);
  }

  .mat-mdc-option {
    --mat-option-label-text-size: 14px;
  }
}
