@use '../utils' as utils;

@include utils.extendTheme() {
  .mat-mdc-card {
    --mdc-elevated-card-container-color: var(--q9-surface-100);
  }

  .mat-mdc-card-title {
    --mat-card-title-text-line-height: 20px;
    --mat-card-title-text-size: 14px;
  }

  .mat-mdc-card-subtitle {
    --mat-card-subtitle-text-size: 12px;
    --mat-card-subtitle-text-weight: 400;
    --mat-card-subtitle-text-color: var(--q9-accent-400);
  }
}
