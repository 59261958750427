@mixin wrap-ellipsis {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  white-space: nowrap;
}

@mixin setTypographyStyles($font-weight) {
  font-size: 14px;
  font-weight: $font-weight;
  line-height: 20px;
}

@mixin circle {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 10px;
}

@mixin general-filter-styles {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  align-items: center;
  border-bottom: 1px solid var(--q9-accent-200);
  background-color: var(--q9-surface-100);
  margin-bottom: 16px;
}

@mixin general-tag-styles {
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
  padding: 2px 6px 2px 6px;
}

@mixin list-item {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 68px;
  padding: 0 12px;

  [list-item-title] {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--q9-accent-500);
  }

  &:hover {
    background-color: var(--q9-accent-100);
    cursor: pointer;

    [list-item-actions] {
      display: block;
    }
  }

  [list-item-actions] {
    display: none;
  }
}

@mixin general-table-styles {
  border: 1px solid var(--q9-accent-200);
  border-radius: 6px;
  max-width: 100%;
  overflow: auto;
}

@mixin colour-sticker {
  .sticker {
    width: fit-content;
    border-radius: 2px;
    padding: 2px 6px;
    margin-bottom: -6px;
    text-align: center;

    &.success,
    &.new {
      color: #fff;
      background-color: #268432;
    }

    &.secondary {
      color: #fff;
      background-color: #7a8c95;
    }

    &.danger {
      color: #fff;
      background-color: var(--q9-warn-300);
    }

    &.warning,
    &.deleted {
      color: #fff;
      background-color: #ca4a41;
    }

    &.orange,
    &.updated {
      color: #fff;
      background-color: #ffa500;
    }

    &.updated {
      color: #ffffff;
      background-color: var(--q9-yellow-450);
    }

    &.pale-red {
      color: var(--q9-violet-100-contrast);
      background-color: var(--q9-violet-100);
    }

    &.extremely {
      color: #ffffff;
      background-color: #a73640;
    }

    &.unchanged {
      color: #ffffff;
      background-color: #7a8c95;
    }

    &.proposed {
      color: #ffffff;
      background-color: #4eaaf4;
    }

    &.initial,
    &.excluded {
      background-color: var(--q9-accent-400);
    }
  }

  .node-status,
  .impact-status {
    border-radius: 6px;
    color: white;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.unchanged {
      color: var(--q9-accent-400);
      background-color: var(--q9-green-100);
    }

    &.updated,
    &.m-impact {
      color: var(--q9-yellow-500);
      background-color: var(--q9-yellow-400);
    }

    &.deleted,
    &.l-impact {
      color: var(--q9-warn-500);
      background-color: var(--q9-warn-100);
    }

    &.new,
    &.h-impact {
      color: var(--q9-green-500);
      background-color: var(--q9-green-100);
    }

    &.proposed {
      background-color: var(--q9-primary-300);
    }

    &.initial {
      background-color: var(--q9-accent-400);
    }

    &.excluded,
    &.na-impact {
      color: var(--q9-accent-500);
      background-color: var(--q9-accent-100);
    }
  }
}
