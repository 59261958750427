@use '../utils' as utils;

@include utils.extendTheme('dark') {
  .mat-mdc-checkbox {
    --mdc-checkbox-selected-icon-color: var(--q9-primary-300);
    --mdc-checkbox-selected-checkmark-color: var(--q9-surface-100);
  }
}

@include utils.extendTheme() {
  .mat-mdc-checkbox {
    --mdc-checkbox-selected-hover-icon-color: var(--q9-primary-400);
    --mdc-checkbox-selected-focus-icon-color: var(--q9-primary-400);
    --mdc-checkbox-disabled-selected-icon-color: var(--q9-accent-300);
    --mat-checkbox-checked-ripple-color: var(--q9-primary-400);
    --mat-checkbox-ripple-color: var(--q9-primary-400);
    --mat-checkbox-label-color: var(--q9-accent-500);
    --mdc-checkbox-unselected-icon-color: var(--q9-accent-400);
    --mdc-checkbox-unselected-hover-icon-color: var(--q9-accent-500);
    --mdc-checkbox-unselected-hover-state-layer-color: none;
    --mdc-checkbox-unselected-pressed-state-layer-color: none;
    --mdc-checkbox-unselected-focus-state-layer-color: none;
    --mdc-checkbox-selected-pressed-state-layer-color: none;
    --mdc-checkbox-selected-focus-state-layer-color: none;
    --mdc-checkbox-selected-hover-state-layer-color: none;
    --mdc-checkbox-disabled-selected-checkmark-color: var(--q9-accent-100);
    --mdc-checkbox-disabled-unselected-icon-color: var(--q9-accent-300);
    --mdc-checkbox-selected-icon-color: var(--q9-primary-300);
    --mdc-checkbox-selected-checkmark-color: var(--q9-primary-100);
  }

  .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
    color: var(--q9-accent-400);
  }

  .mat-mdc-checkbox {
    .mdc-form-field {
      align-items: initial;
      color: var(--q9-accent-500);

      .mdc-checkbox {
        display: contents;
        padding: 0;
        margin: 0 8px;

        &__ripple {
          transform: scale(2.2);
        }

        &__native-control {
          position: static;
        }

        &__background {
          border-width: 1px;
          position: absolute;

          .mdc-checkbox__checkmark-path {
            stroke-width: 4px;
            stroke-linejoin: round;
            stroke-linecap: round;
            transform: scale(0.7) translateX(5px) translateY(5px);
          }

          .mdc-checkbox__mixedmark {
            border-radius: 2px;
            width: 10px;
          }
        }
      }

      label {
        padding: 0 3px 0 3px;
      }
    }

    &:not(.cdk-keyboard-focused):not(.cdk-program-focused)
      .mdc-checkbox__ripple {
      display: none;
    }
  }
}
