@use '../utils' as utils;

@mixin selectedItemStyles($box-shadow) {
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: $box-shadow;
    background-color: var(--q9-surface-100);
    opacity: 0.3;
  }
}

[cdkDropListGroup] {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

[cdkDropList] {
  border: 1px solid var(--q9-primary-300);
  border-radius: 6px;
  height: 265px;
  overflow: auto;
  min-width: 225px;
  margin-top: 8px;
}

[cdkDrag] {
  padding: 10px 12px;
  border-bottom: 1px solid var(--q9-accent-300);
  color: var(--q9-accent-500);
  cursor: pointer;
}

@include utils.extendTheme('light') {
  $box-shadow: 0 3px 5px 0 var(--q9-accent-300);

  @include selectedItemStyles($box-shadow);
}

@include utils.extendTheme('dark') {
  $box-shadow: 0 3px 10px 0 var(--q9-accent-100);

  @include selectedItemStyles($box-shadow);
}
