@use '../utils' as utils;
@use '@angular/material' as mat;

@include utils.extendTheme() {
  button {
    --mdc-typography-button-letter-spacing: normal;
    --mdc-typography-button-font-weight: 600;
    outline-width: 0 !important;

    mat-spinner svg {
      --mdc-circular-progress-active-indicator-color: #{currentColor};
    }

    &.mdc-button:not([mat-icon-button], [mat-fab], [mat-mini-fab]) {
      height: 40px;
    }

    &[mat-icon-button].mat-mdc-icon-button {
      padding: 8px;
      --mdc-icon-button-state-layer-size: 40px;
      --mdc-icon-button-icon-color: var(--q9-accent-500);

      .mat-mdc-button-touch-target {
        height: var(--mdc-icon-button-state-layer-size);
        width: var(--mdc-icon-button-state-layer-size);
      }
    }
  }

  .mat-mdc-outlined-button {
    --mdc-outlined-button-outline-width: 1px;
    --mdc-outlined-button-outline-color: #{currentColor};
  }
}

@include utils.extendTheme('dark') {
  button[mat-icon-button][disabled],
  button[mat-icon-button][disabled][disabled] {
    --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.2);
  }
}

@include utils.extendTheme('light') {
  button[mat-icon-button][disabled] {
    --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.2);
  }
}
