@use '@angular/material' as mat;
@use '../utils' as utils;

@include utils.extendTheme() {
  --mdc-shape-small: 6px;
}

@import 'form-field';
@import 'button';
@import 'select';
@import 'icon';
@import 'sidenav';
@import 'tooltip';
@import 'slide-toggle';
@import 'radio-button';
@import 'checkbox';
@import 'tabs';
@import 'snack-bar';
@import 'modal';
@import 'table';
@import 'menu';
@import 'toolbar';
@import 'expansion-panel';
@import 'kendo/_index';
@import 'section';
@import 'card';
@import 'tags-combobox';
@import 'autocomplete';
@import 'draggable-list';
@import 'rich-text-field';
@import 'trial';
@import 'popup';
@import 'user-info';
@import 'counter';
@import 'stepper';
@import 'datetime-picker';
@import 'froala-styles';
