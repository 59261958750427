@use '../utils' as utils;

@include utils.extendTheme() {
  .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 6px;
    --mdc-dialog-subhead-size: 18px;
    --mdc-dialog-subhead-weight: 600;
    --mdc-dialog-subhead-line-height: 26px;
    --mdc-dialog-supporting-text-size: 14px;
    --mdc-dialog-supporting-text-line-height: 18px;
    --mdc-dialog-container-color: var(--q9-surface-100);
    --mdc-dialog-subhead-color: var(--q9-accent-500);
  }

  .mat-mdc-dialog-surface {
    padding: 26px;
  }

  .mdc-dialog {
    [mat-dialog-title],
    [matDialogTitle] {
      display: flex;
      padding: 0;
      margin-bottom: 26px;

      &:before {
        height: auto;
      }

      & > :first-child {
        flex: 1;
      }

      & > :first-child,
      & > :last-child {
        margin: auto;
      }
    }

    [mat-dialog-content],
    mat-dialog-content,
    [matDialogContent] {
      padding: 6px 0 0;
    }

    [mat-dialog-actions],
    mat-dialog-actions,
    [matDialogActions] {
      display: flex;
      justify-content: end;
      border-top: 0;
      gap: 16px;
      padding: 0;
      margin-top: 10px;
      min-height: 40px;
    }
  }
}
