@use '../../utils' as utils;

q9-grid kendo-grid.k-grid {
  border-radius: 5px;
  overflow: hidden;

  kendo-pager {
    position: sticky;
    bottom: 0;
    z-index: 2;
  }

  td,
  .k-table-td {
    padding: 0;

    .cell-content {
      height: 100%;
      padding: 16px;
      overflow: auto;
    }
  }

  .k-table-row {
    th,
    .k-table-th,
    td,
    .k-table-td {
      border-inline-start-color: var(--q9-accent-200);
      text-overflow: unset;

      .cell-content {
        max-height: 56px;
      }

      &:only-child,
      &:last-child {
        border-inline-end-width: 1px;
      }

      &.k-grid-content-sticky {
        border-color: var(--q9-accent-200);
      }

      &.checkbox-column {
        text-align: center;
      }
    }

    &:only-child,
    &:last-child {
      td,
      .k-table-td {
        border-bottom-width: 1px;
      }
    }
  }

  .k-grid-norecords q9-placeholder {
    position: absolute;
    left: 0;
    width: 100%;
  }

  .k-svg-i-filter {
    width: 24px;
    height: 24px;
  }
}

kendo-grid-filter-menu-container {
  width: 250px;

  mat-form-field {
    width: 100%;
  }

  .k-button {
    text-transform: unset;
  }

  .k-actions-horizontal {
    flex-flow: row-reverse nowrap;
  }

  .k-filter-menu-container {
    padding-top: 12px;
  }
}

.k-menu-popup {
  border-radius: 5px;
}

.k-menu-group,
.k-menu-popup,
.k-menu.k-context-menu {
  .k-item {
    height: 36px;

    & > .k-link mat-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .k-menu-expand-arrow {
    display: none;
  }
}

[kendowatermarkoverlay] {
  display: none !important;
}
