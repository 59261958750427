@use '../utils' as utils;

@include utils.extendTheme() {
  .mat-mdc-tab-header {
    --mdc-secondary-navigation-tab-container-height: 56px;
    --mat-tab-header-label-text-weight: 600;
    --mat-tab-header-label-text-line-height: 20px;
    --mat-tab-header-active-ripple-color: var(--q9-primary-300);
    --mat-tab-header-active-label-text-color: var(--q9-primary-300);
    --mat-tab-header-inactive-label-text-color: var(--q9-accent-500);
    --mat-tab-header-inactive-hover-label-text-color: var(--q9-accent-500);
    --mat-tab-header-inactive-ripple-color: var(--q9-accent-500);
  }

  .mat-mdc-tab {
    .mat-mdc-tab-ripple {
      padding: 18px 12px;
    }
  }
}
