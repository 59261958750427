@use '../utils' as utils;
@use '../mixins' as mixins;

@include utils.extendTheme() {
  .mat-mdc-slide-toggle {
    --mdc-switch-handle-surface-color: var(--q9-surface-light-contrast);
    --mdc-switch-unselected-focus-handle-color: var(--q9-accent-400);
    --mdc-switch-unselected-hover-handle-color: var(--q9-accent-400);
    --mdc-switch-disabled-selected-handle-color: var(--q9-surface-100);
    --mdc-switch-disabled-selected-icon-color: var(--q9-accent-500);
    --mdc-switch-disabled-selected-track-color: var(--q9-accent-200);
    --mdc-switch-disabled-unselected-handle-color: var(--q9-accent-200);
    --mdc-switch-disabled-unselected-track-color: var(--q9-surface-100);
    --mdc-switch-selected-icon-color: var(--q9-accent-500);
    --mdc-switch-unselected-handle-color: var(--q9-accent-400);
    --mdc-switch-unselected-hover-track-color: var(--q9-surface-100);
    --mdc-switch-unselected-pressed-handle-color: var(--q9-accent-400);
    --mdc-switch-unselected-track-color: var(--q9-surface-100);
    --mdc-switch-selected-icon-size: 14px;
    --mdc-switch-unselected-pressed-state-layer-opacity: 0;
    --mdc-switch-handle-elevation-shadow: 0;
    --mdc-theme-text-primary-on-background: var(--q9-accent-500);
    --mat-slide-toggle-label-text-size: 14px;

    &.mat-accent {
      --mdc-switch-selected-focus-track-color: var(--q9-primary-300);
      --mdc-switch-selected-track-color: var(--q9-primary-300);
      --mdc-switch-selected-hover-track-color: var(--q9-primary-300);
    }
  }

  .mdc-switch {
    --mdc-switch-track-width: 40px;
    --mdc-switch-selected-icon-size: 14px;
    --mdc-switch-disabled-handle-opacity: 1;
    --mdc-switch-track-shape: 100px;
    --mdc-switch-track-height: 26px;
    --mdc-switch-disabled-track-opacity: 1;

    .mdc-switch__ripple {
      --mdc-switch-state-layer-size: 20px;
    }

    &.mdc-switch--unselected {
      .mdc-switch__handle-track {
        margin-left: 3px;
      }

      .mdc-switch__icons {
        display: none;
      }
    }

    .mdc-switch__handle-track {
      width: calc(100% - var(--mdc-switch-handle-width) - 3px);
    }

    &__track {
      &::before {
        border-color: var(--q9-accent-400);
      }
    }

    &--disabled {
      .mdc-switch__track {
        &::before {
          border-color: var(--q9-accent-200);
        }
      }

      & + label {
        color: var(--q9-accent-400);
      }
    }
  }

  .mat-mdc-slide-toggle {
    .mdc-label {
      padding-left: 15px;

      @include mixins.setTypographyStyles(600);
    }
  }
}

@include utils.extendTheme('dark') {
  .mat-mdc-slide-toggle {
    --mdc-switch-disabled-unselected-track-color: var(--q9-surface-200);

    &.mat-accent {
      --mdc-switch-selected-handle-color: var(--q9-extra-200);
      --mdc-switch-handle-surface-color: var(--q9-surface-200);
    }
  }
}
