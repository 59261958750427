@use '../utils' as utils;
@use '@angular/material' as mat;

@include utils.extendTheme() {
  mat-expansion-panel {
    --mat-expansion-container-shape: 0;
    --mat-expansion-header-hover-state-layer-color: none;
    --mat-expansion-header-focus-state-layer-color: none;
    --mat-expansion-header-indicator-color: var(--q9-accent-500);
    background: none;
    margin-bottom: 0;

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }

    .mat-expansion-panel-header {
      padding: 0 24px 0 0;
    }
  }

  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    @include mat.elevation(0);
  }
}
