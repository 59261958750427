.fr-second-toolbar {
  #fr-logo {
    display: none;
  }
}

.fr-fullscreen-wrapper {
  height: 100vh;
}

p.fr-fullscreen-wrapper {
  position: absolute;
  top: 0;
}
