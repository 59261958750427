@use '@angular/material' as mat;
@use '../utils' as utils;

@include utils.extendTheme() {
  .mat-icon svg {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  mat-icon.without-stroke-width {
    svg {
      stroke-width: 0;
    }
  }
}
