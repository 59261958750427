@use '../utils' as utils;

@include utils.extendTheme('dark') {
  .mat-mdc-radio-button.mat-primary {
    --mdc-radio-unselected-focus-icon-color: var(--q9-accent-500);
  }
}

@include utils.extendTheme() {
  .mat-mdc-radio-button.mat-primary {
    --mdc-radio-disabled-selected-icon-opacity: 50%;
    --mdc-radio-disabled-unselected-icon-opacity: 50%;
    --mdc-radio-selected-hover-icon-color: var(--q9-primary-400);
    --mdc-radio-selected-focus-icon-color: var(--q9-primary-400);
    --mdc-radio-unselected-hover-icon-color: var(--q9-accent-500);
    --mdc-radio-unselected-icon-color: var(--q9-accent-400);
    --mat-radio-disabled-label-color: var(--q9-accent-400);
    --mdc-radio-disabled-selected-icon-color: var(--q9-accent-300);
    --mdc-radio-disabled-unselected-icon-color: var(--q9-accent-300);
    --mat-radio-checked-ripple-color: none;
    --mat-radio-ripple-color: none;
    --mat-radio-label-color: var(--q9-accent-500);
  }

  .mat-mdc-radio-button {
    .mdc-form-field {
      align-items: initial;

      .mdc-radio {
        margin: 0 8px 0 8px;
        padding: 0;
        transform: scale(0.9);

        &__outer-circle {
          border-width: 1px;
        }
      }

      .mdc-radio--disabled + label {
        color: var(--mat-radio-disabled-label-color);
      }

      .mdc-label {
        color: var(--mat-radio-label-color);
        padding: 0 3px 0 3px;
      }
    }
  }

  .mat-mdc-radio-button.cdk-focused
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background {
    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      border-color: var(--mdc-radio-selected-focus-icon-color);
    }
  }

  .mdc-radio__native-control:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    transform: scale(0.55);
  }
}
