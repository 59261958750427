html,
body {
  min-height: 100vh;
  height: auto;
  margin: 0;
  overflow: hidden;
}

q9-sidebar {
  height: calc(100vh - 127px);
}

.q9-grid-wrapper {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;

  q9-grid {
    $margin: 16px;

    flex: 1;
    //143px is the sum of heights of the toolbar and of the header
    height: calc(100vh - 143px);
    margin: $margin;
    width: calc(100% - $margin * 2);
  }
}
