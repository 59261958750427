@use '../utils' as utils;
@use '../dark' as q9-dark;
@use '../light' as q9-light;

.fr-box,
.fr-box.fr-document {
  min-width: auto;

  .fr-wrapper,
  .fr-toolbar {
    border: none !important;
    background: transparent !important;
  }

  .fr-wrapper {
    min-width: auto;
    margin: 8px 12px;
    padding: 0;

    .fr-element {
      width: auto;
      min-height: auto !important;
      height: 100%;
      padding: 0;
      color: var(--q9-accent-300-contrast) !important;

      &.fr-view p {
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
  }

  .fr-toolbar {
    .fr-command.fr-btn svg path {
      fill: var(--q9-accent-500) !important;
    }

    &.fr-bottom .fr-newline {
      height: var(--mdc-outlined-text-field-outline-width);
      background: var(--mdc-outlined-text-field-outline-color);
    }
  }
}
