@use '../utils' as utils;
@use '../dark' as q9-dark;
@use '../light' as q9-light;

@mixin svg-arrow($stroke-color) {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 9L12 15L18 9" stroke="#{$stroke-color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

@include utils.extendTheme('dark') {
  $dark-background: #424242;

  $focused-stroke-color: utils.encodeColor(
    utils.map-deep-get(q9-dark.$primary, 300)
  );

  $default-stroke-color: utils.encodeColor(
    utils.map-deep-get(q9-light.$accent, 400)
  );

  $warn-stroke-color: utils.encodeColor(utils.map-deep-get(q9-dark.$warn, 300));

  .ng-select {
    &.ng-select-focused .ng-arrow-wrapper .ng-arrow {
      @include svg-arrow($focused-stroke-color);
    }

    &:not(.ng-select-focused) .ng-arrow-wrapper .ng-arrow {
      @include svg-arrow($default-stroke-color);
    }

    &.ng-select-focused.is-tag-error .ng-arrow-wrapper .ng-arrow {
      @include svg-arrow($warn-stroke-color);
    }

    &.is-tag-error .ng-arrow-wrapper .ng-arrow {
      @include svg-arrow($warn-stroke-color);
    }

    .ng-input > input {
      color: var(--mdc-outlined-text-field-input-text-color);
    }
  }

  .ng-dropdown-panel {
    background-color: $dark-background;
  }

  .q9-tags-select-wrapper {
    .q9-tags-label {
      background-color: $dark-background;
    }
  }
}

@include utils.extendTheme('light') {
  $focused-stroke-color: utils.encodeColor(
    utils.map-deep-get(q9-light.$primary, 300)
  );

  $default-stroke-color: utils.encodeColor(
    utils.map-deep-get(q9-light.$accent, 400)
  );

  $warn-stroke-color: utils.encodeColor(
    utils.map-deep-get(q9-light.$warn, 300)
  );

  .ng-select {
    &.ng-select-focused .ng-arrow-wrapper .ng-arrow {
      @include svg-arrow($focused-stroke-color);
    }

    &:not(.ng-select-focused) .ng-arrow-wrapper .ng-arrow {
      @include svg-arrow($default-stroke-color);
    }

    &.ng-select-focused.is-tag-error .ng-arrow-wrapper .ng-arrow {
      @include svg-arrow($warn-stroke-color);
    }

    &.is-tag-error .ng-arrow-wrapper .ng-arrow {
      @include svg-arrow($warn-stroke-color);
    }
  }

  .q9-tags-select-wrapper {
    .q9-tags-label {
      background-color: var(--q9-surface-100);
    }
  }

  .ng-dropdown-panel {
    background-color: var(--q9-surface-100);
  }
}

@include utils.extendTheme() {
  .q9-tags-select-focused {
    .q9-tags-label:not(.error-label) {
      color: var(--q9-primary-300) !important;
    }

    .q9-tags-label.error-label {
      color: var(--q9-warn-300);
    }
  }

  .q9-tags-select-wrapper {
    position: relative;
    width: 204px;

    .tag-error {
      line-height: 20px;
      font-size: 12px;
    }

    .q9-tags-label {
      position: absolute;
      display: flex;
      align-items: center;
      color: var(--mdc-outlined-text-field-label-text-color);
      top: 19px;
      z-index: 1002;
      font-size: 12px;
      left: 12px;
      height: 14px;
      padding: 0 2px;
    }

    .q9-tags-label.error-label {
      color: var(--q9-warn-300);
    }
  }

  .ng-select {
    &.ng-select-multiple {
      .ng-select-container {
        border: 1px solid var(--q9-accent-300);
        border-radius: 6px;
        padding: 10px 12px;
        height: 40px;
        display: flex;
        flex-wrap: nowrap;
        max-width: 204px;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;

        .ng-value-container {
          overflow: auto;
          flex-wrap: nowrap !important;
        }

        .ng-value-container::-webkit-scrollbar {
          height: 0;
        }
      }
    }

    &.q9-tag-select {
      &.ng-select-multiple {
        .ng-select-container:hover {
          border: 1px solid var(--q9-accent-500);
        }

        .ng-value {
          display: flex;
          align-items: center;
          flex: 0 0 auto;
          white-space: nowrap;
          margin-right: 6px;
        }

        .q9-value-label {
          display: flex;
          color: var(--mdc-outlined-text-field-label-text-color);
          align-items: center;
          gap: 1px;
          font-size: 12px;
          padding: 2px 6px;
          background-color: var(--q9-accent-100);
          margin-right: 1px;
          border-radius: 2px 0 0 2px;
          line-height: 14px;
        }

        .q9-value-icon {
          height: 16px;
          width: 16px;
          background-color: var(--q9-accent-100);
          padding: 1px;
          border-radius: 0 2px 2px 0;
        }

        .ng-input {
          min-width: 30px;
          flex: 0 0 auto;
          white-space: nowrap;
        }

        &.ng-select-focused .ng-select-container {
          border: 2px solid var(--q9-primary-300);
        }

        &.ng-select-focused.is-tag-error .ng-select-container {
          border: 2px solid var(--q9-warn-300);
        }

        &.is-tag-error .ng-select-container {
          border: 1px solid var(--q9-warn-300);
        }

        &.is-tag-error .ng-placeholder {
          color: var(--q9-warn-300);
        }

        .ng-placeholder {
          color: var(--mdc-outlined-text-field-label-text-color);
          letter-spacing: var(--mdc-outlined-text-field-label-text-tracking);
        }
      }

      .ng-arrow-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .ng-arrow {
          height: 20px;
          width: 20px;
          background-size: 20px;
        }
      }

      .ng-clear-wrapper {
        color: var(--q9-accent-400);
        display: none;
      }
    }
  }

  .ng-select.ng-select-opened {
    .ng-arrow-wrapper {
      transform: rotate(180deg);
    }
  }
}

.ng-dropdown-panel {
  border-radius: 6px;
  box-shadow: 0 3px 5px 0 var(--q9-accent-200);
  padding: 6px 0;

  .ng-dropdown-panel-items {
    max-height: 216px;
  }

  .ng-option {
    height: 36px;
    padding: 8px 12px;

    &:first-child {
      border-radius: 6px 6px 0 0;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }

  .ng-option-marked:not(.ng-option-selected):not(.ng-option-disabled) {
    background-color: var(--q9-primary-100);
  }

  .ng-option-disabled {
    background-color: var(--q9-accent-100);
  }

  .ng-option-selected {
    background-color: var(--q9-primary-300);
    color: var(--q9-surface-100);
  }
}
