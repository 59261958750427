@use '../utils' as utils;
@use '../mixins' as mixins;
@use '@angular/material' as mat;

@include utils.extendTheme('dark') {
  --mat-table-background-color: var(--q9-surface-100);
}

@include utils.extendTheme() {
  --mat-table-header-headline-color: var(--q9-accent-500);
  --mat-table-header-headline-weight: 700;
  --mat-table-header-headline-line-height: 20px;
  --mat-table-row-item-label-text-color: var(--q9-accent-500);
  --mat-paginator-select-trigger-text-size: 14px;
  --mat-paginator-container-text-size: 14px;
  --mat-paginator-container-text-color: var(--q9-accent-500);
  --mdc-outlined-text-field-outline-color: var(--q9-accent-300);
  --mat-paginator-disabled-icon-color: var(--q9-accent-300);
  --mat-paginator-enabled-icon-color: var(--q9-accent-500);
  --mat-select-enabled-trigger-text-color: var(--q9-accent-500);
  --mat-paginator-container-background-color: transparent;

  mat-table {
    .mat-mdc-row:hover {
      background-color: var(--q9-accent-100);
      cursor: pointer;
    }

    .active,
    .mat-mdc-row.active:hover {
      background-color: var(--q9-primary-100);
    }

    a {
      color: var(--q9-primary-300);
      text-decoration: none;

      &:hover {
        color: var(--q9-primary-400);
      }
    }
  }

  .mat-mdc-paginator {
    .mat-mdc-paginator-container {
      flex-wrap: unset;

      .mat-mdc-paginator-page-size {
        align-items: center;
      }
    }
  }

  .mat-sort-header-arrow {
    color: var(--mat-table-header-headline-color);
  }
}

.q9-table-wrapper {
  display: inline-flex;
  width: 100%;

  .q9-table-container {
    .table {
      border: 1px solid var(--q9-accent-200);
      border-radius: 6px;
      max-width: 100%;
      overflow: auto;
    }
  }

  q9-table,
  [q9CustomTable] {
    min-width: 395px;
    flex-basis: 100%;
    margin: 0 16px 0 16px;

    mat-table {
      max-height: calc(100vh - 336px);
      min-width: 800px;

      mat-cell {
        display: block;
        line-height: 45px;
        width: 50px;

        @include mixins.wrap-ellipsis;
      }

      mat-cell:first-of-type,
      mat-header-cell:first-of-type,
      mat-footer-cell:first-of-type,
      mat-cell:last-of-type,
      mat-header-cell:last-of-type,
      mat-footer-cell:last-of-type {
        padding: 0 16px;
      }
    }
  }

  [q9Sidebar] {
    margin-right: 16px;
  }

  .mat-mdc-table-sticky-border-elem-right {
    border-left: 1px solid var(--q9-accent-200);
  }

  .mat-mdc-table-sticky-border-elem-left {
    border-right: 1px solid var(--q9-accent-200);
  }
}
