.mat-mdc-autocomplete-panel {
  --mat-option-hover-state-layer-color: var(--q9-primary-100);
  --mat-option-selected-state-layer-color: var(--q9-primary-300);
  --mat-option-selected-state-label-text-color: var(--q9-surface-200);

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
      .mat-mdc-option-multiple
    ) {
    q9-user-icon {
      color: var(--q9-surface-100);
    }

    q9-message {
      .q9-message-container {
        &_message {
          .title-container {
            q9-message-title,
            q9-message-subtitle {
              color: var(--q9-surface-200);
            }
          }
        }
      }
    }
  }
}
