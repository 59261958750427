@use '../utils' as utils;

@include utils.extendTheme() {
  --mat-toolbar-standard-height: 55px;
  --mat-toolbar-container-background-color: var(--q9-surface-100);

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    padding: 9px 16px;
  }

  mat-toolbar {
    mat-divider.mat-divider-vertical {
      height: calc(var(--mat-toolbar-standard-height) - 16px);
    }
  }
}
