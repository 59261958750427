@use '../../styles/mixins' as mixins;

.q9-popup-overlay {
  overflow: auto;
  overflow-x: hidden;
  border-radius: 6px;
  border: 1px solid var(--q9-accent-200);
  background-color: var(--q9-surface-100);
  margin-bottom: 100px;
}

.q9-popup-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  letter-spacing: 0;

  .popup-title-container {
    display: flex;
    gap: 8px;

    .popup-title-data {
      @include mixins.wrap-ellipsis();

      flex: 1;
      width: 300px;

      .popup-title {
        @include mixins.wrap-ellipsis();

        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
      }

      .popup-subtitle {
        display: flex;
        flex-direction: column;
        gap: 4px;
        color: var(--q9-accent-400);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 6px;

        &,
        & > * {
          @include mixins.wrap-ellipsis();
        }
      }
    }
  }
}
