@use '../utils' as utils;

@include utils.extendTheme() {
  .mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: var(--q9-accent-500);
    --mdc-snackbar-container-shape: 6px;

    height: 52px;
    margin: 20px;

    .mdc-snackbar {
      &__surface {
        height: 52px;
        padding-right: 0;
        min-width: 0;
      }

      &__label {
        padding: 16px;
      }
    }

    &.error-snackbar {
      --mdc-snackbar-container-color: var(--q9-warn-100);
      --mdc-snackbar-supporting-text-color: var(--q9-warn-500);
    }

    &.success-snackbar {
      --mdc-snackbar-container-color: var(--q9-green-100);
      --mdc-snackbar-supporting-text-color: var(--q9-green-500);
    }
  }
}
