@use '../utils' as utils;

@include utils.extendTheme() {
  --mat-stepper-header-height: 44px;
  --mat-stepper-header-hover-state-layer-color: var(--q9-accent-100);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--q9-accent-500);

  mat-stepper {
    --mat-stepper-container-color: initial;

    mat-step-header {
      padding: 8px;
      border-radius: 4px;

      .mat-step-label {
        .mat-step-text-label {
          font-size: 14px;
        }
      }
    }

    .mat-horizontal-content-container {
      padding: 0;
    }

    .mat-step-icon-state-edit {
      background-color: var(--q9-primary-200);
    }
  }
}
