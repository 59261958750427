.q9-menu-overlay {
  .mat-mdc-menu-content {
    padding: 6px 0;
  }

  [mat-menu-item][color='warn'] {
    color: var(--q9-warn-300);
  }
}

.q9-menu-overlay {
  .dots-menu {
    border-radius: 6px;
  }

  .mat-mdc-menu-content {
    --mat-menu-item-label-text-size: 14px;
    --mat-menu-item-label-text-weight: 400;
    --mat-menu-item-label-text-line-height: 20px;
    --mat-menu-item-hover-state-layer-color: var(--q9-primary-100);
  }
}
